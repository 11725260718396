/**
 * Note: this file only serves as entry point and imports all required functionality from modules.
 * These modules are responsible to check whether they are applicable on the current site and only then load their business logic.
 * This way, we can have the best of both worlds, tree-shaking and dynamic imports.
 *
 * When writing modules, make sure to 'register' them in the corresponding index.js and reference them only over this file.
 *
 * If a module is completely not used on a project, comment the corresponding function calls here.
 */

// breakpoint on which headroom should be active
// NOTE: has to match breakpoint in resources/components/patterns/molecules/headroom/_headroom.scss
const breakpoint = 'desktop';

import * as application from './application';

// ////////////////////////////////////////////////////////////
// run bugsnag as early as possible
application.bugsnag();

// ////////////////////////////////////////////////////////////
// check if cookies are enabled
// - add the .no-cookies class to <html /> if not
let cookieEnabled = Boolean(navigator.cookieEnabled);

if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
    document.cookie = 'testcookie';
    cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
}

if (!cookieEnabled) {
    document.documentElement.classList.add('no-cookies');
}

// ////////////////////////////////////////////////////////////
// include critical polyfills very early
import * as polyfills from '@spinnwerk/polyfills';
polyfills.focusOptions();

// IE11 polyfills
// polyfills.dom();
// polyfills.fetch();
// polyfills.objectFit();
// polyfills.picturefill();
// polyfills.intersectionObserver();

// ////////////////////////////////////////////////////////////
// image lazy loading
import(/* webpackMode: "lazy" */ 'lazysizes');

// enable support for native lazy loading
/* note: native lazy loading has two major downsides:
 * it loads images much earlier than lazysizes when scrolling down and has no possibility to adjust this value
 * and it loads *all* images above or near the 'fold' regardless of their current visibility state (e.g. in sliders/accordions etc).
 * See https://web.dev/native-lazy-loading#how-does-the-loading-attribute-work-with-images-that-are-in-the-viewport-but-not-immediately-visible-(for-example-behind-a-carousel)
 * If your project is fine with this, feel free to enable the following code and remove the above import:
// import lazySizes from 'lazysizes';
// import 'lazysizes/plugins/native-loading/ls.native-loading';
// lazySizes.cfg.nativeLoading = {
//     setLoadingAttribute: true,
//     disableListeners: true, // remove all event listeners added by lazysizes in browsers that support native lazy loading
// };
 */

// ////////////////////////////////////////////////////////////
// Framework initialization
import * as foundation from './_foundation';
foundation.init();

// ////////////////////////////////////////////////////////////
// Hack vor having full 100vh support on mobile devices
import { fullHeightOnMobile } from './utils/full-height-on-mobile';
fullHeightOnMobile();

// ////////////////////////////////////////////////////////////
// Adds custom classes based on device orientation
import { deviceOrientation } from './utils/device-orientation';
deviceOrientation();

// ////////////////////////////////////////////////////////////
// Security
import { initSecuringExternalAnchors } from './security/external-anchors';
initSecuringExternalAnchors();

// ////////////////////////////////////////////////////////////
// Application setup

// register service worker in production mode
application.serviceWorker();

// optimized font loading
// fonts are defined in app/etc/.fontsrc.json and automatically added to CONFIG
import { loadFonts } from '../../components/base/fonts/fonts';
loadFonts(CONFIG.fonts);
delete CONFIG.fonts; // release memory, fonts are not needed anymore

// ux / accessibility features
application.handleTabKey();

// init all scrolling belonging
application.autoScrolling({
    fromHashChanges: CONFIG.scrollFromHashChanges,
    offset: {
        small: 0,
        [breakpoint]: {
            up: '.header',
            down: '.header',
        },
    },
    getTargetId(hash) {
        return hash.replace(/#\/?/, '');
    },
    beforeScroll(target, $target) {
        /* eslint-disable jquery/no-is, jquery/no-closest */
        // jQuery is required to open targeted Accordion-items anyways, so it is fine to use it */
        if ($target.is('[data-tab-content]')) {
            $target.closest('[data-accordion]').foundation('down', $target);
        }
        /* eslint-enable */
    },
});

// hide elements when others appear
application.clearAway({
    target: '.section-nav',
    trigger: '.footer',
});

// ////////////////////////////////////////////////////////////
// Navigation

// update URL and state links based on scrolling
import { sectionNav } from '../../components/patterns/molecules/navigation';

// only use sectionNav on front-page
if (document.querySelector('body.home')) {
    sectionNav({
        getHashFromElement(el) {
            return '/' + (el.dataset.sectionNavAnchor || el.id);
        },
        getActiveStateSelector(hash) {
            return `[href$="#${hash.replace(/^#?\//, '')}"]`;
        },
    });
}

// ////////////////////////////////////////////////////////////
// form helpers
import * as form from '../../components/patterns/molecules/form';

function initFormHelpers() {
    // enable validation
    form.validation().then((parsleyOptions) => {
        form.steps(); // validation needs to be in place before multi-step forms can be initialized

        // automatically attach a loading indicator to submit buttons on form submit
        // but make sure, the validation is attached before
        form.loadingIndicator({
            exclude: 'button[name="apply_coupon"]', // deactivate for woocommerce checkout coupon submit
        });

        // correctly validate shop checkout
        form.shopCheckoutValidation(parsleyOptions);
    });

    // enable automatic summaries
    form.summaries();

    // enable conditional inputs
    form.conditionals();

    // enable (de-)selecting all checkboxes at once
    form.selectAll();

    // style advanced file inputs
    form.fileInput({
        multipleFilesLabel: ':anz Dateien ausgewählt',
    });

    // enable multi file uploads
    form.multiUpload();

    // manage hierarchical checkboxes
    form.hierarchicalCheckboxes();

    // manage disabled states of checkboxes and radiobuttons
    form.radioCheckboxes();
}

initFormHelpers();

// WooCommerce replaces DOM completely, so all functionality must be applied again (-> missing event handlers)
document.body.addEventListener(
    'updated_wc_div',
    () => initFormHelpers(),
    polyfills.eventListenerOptions({ passive: true }),
);

// ////////////////////////////////////////////////////////////
// atoms
import { animateSVG } from '../../components/patterns/atoms/animate';
animateSVG();

// ////////////////////////////////////////////////////////////
// molecules
import { scrollUp } from '../../components/patterns/molecules/scroll-up/scroll-up';
scrollUp({
    createButton:
        'createScrollUpButton' in CONFIG ? CONFIG.createScrollUpButton : !document.querySelector('.section-nav'),
});

import { initHeadroom } from '../../components/patterns/molecules/headroom';
application.globalState.set(
    'headroom',
    initHeadroom({
        prependSpacerTo: false,
        breakpoint,
        offset: {
            small: 0,
            [breakpoint]: {
                up: '[data-headroom-offset]',
                down: 0,
            },
        },
    }),
);

import { fontSizeSwitcher } from '../../components/patterns/molecules/font-size-switcher';
fontSizeSwitcher();

import { callout } from '../../components/patterns/molecules/callout/callout';
callout();

import { cards } from '../../components/patterns/molecules/card/cards';
cards();

import { counter } from '../../components/patterns/molecules/counter';
counter();

import { filters } from '../../components/patterns/molecules/filter';
filters();

import { lightbox } from '../../components/patterns/molecules/lightbox';
lightbox();

// ////////////////////////////////////////////////////////////
// layouts
import { masonry } from '../../components/layout/masonry';
masonry();

import { swiper } from '../../components/layout/swiper';
swiper();

import { maps } from '../../components/layout/map';
maps();

// ////////////////////////////////////////////////////////////
// browser alerts
import { oldBrowserAlert } from '../../components/patterns/molecules/browser-alerts';
oldBrowserAlert();
// fix a11y flaws of Borlabs Cookie Manager
import { borlabsCookieA11yFixes } from '../../components/patterns/molecules/browser-alerts/borlabs-cookies';
borlabsCookieA11yFixes();

// ////////////////////////////////////////////////////////////
// init web share
import { share } from '../../components/patterns/organisms/share';
share();

// ////////////////////////////////////////////////////////////
// init modals
import { modal } from '../../components/patterns/molecules/modal';
modal();

// ////////////////////////////////////////////////////////////
// global popup
import { popup } from '../../components/patterns/organisms/popup';
popup();

// ////////////////////////////////////////////////////////////
// Enable ajax add to cart & product variations
/* eslint-disable */
function toggleAddToCartLoadingIndicator(added, el) {
    if (added === true) {
        el.classList.toggle('is-loading');
        el.classList.toggle('is-loading--svg-active');
    } else {
        el.classList.toggle('is-loading');
        el.classList.toggle('is-loading--svg-active');
    }
}

function changeAddToCartProductId(optionDataset) {
    const data = optionDataset,
        parentId = data.parentProductId,
        variationId = data.variationId,
        variationPrice = data.salePrice ? data.salePrice : data.regularPrice,
        addToCartBtn = document.querySelector(
            `[data-parent-product-variation-id="${parentId}"][data-ajax-add-to-cart-btn]`,
        ),
        productPrice = document.querySelector(`[data-parent-product-variation-id="${parentId}"][data-product-price]`);

    // update add_to_cart_btn with correct variation product id
    addToCartBtn.dataset.product_id = variationId;
    addToCartBtn.setAttribute('href', `?add-to-cart=${variationId}`);
    addToCartBtn.closest('form.cart').setAttribute('action', `?add-to-cart=${variationId}`);

    // change price of product variation according to current selected variation
    productPrice.textContent = `${variationPrice}`;
}

let ajaxAddToCartLinks = document.querySelectorAll('.ajax_add_to_cart'),
    regularAddToCartSubmitButton = document.querySelectorAll('.simple_add_to_cart'),
    inputQuantity = document.querySelectorAll('input.qty'),
    productVariations = document.querySelectorAll('[data-product-variations]');

if (ajaxAddToCartLinks.length > 0) {
    // toggle loading indicator for ajax add to cart button
    jQuery(document.body).on('adding_to_cart', function () {
        toggleAddToCartLoadingIndicator(false, document.querySelector('a.ajax_add_to_cart.loading'));
    });

    jQuery(document.body).on('added_to_cart', function () {
        toggleAddToCartLoadingIndicator(true, document.querySelector('a.ajax_add_to_cart.is-loading'));
        inputQuantity.forEach(function (el) {
            // reset quantity field
            el.value = 1;
        });
    });

    // hide regular submit button when JS is active
    regularAddToCartSubmitButton.forEach(function (submit) {
        submit.style.display = 'none';
    });

    ajaxAddToCartLinks.forEach(function (el) {
        el.style.display = 'block';

        el.addEventListener('click', function () {
            // set data-quantity of 'ajax add to cart' to current value set in the quantity field
            this.dataset.quantity = this.previousElementSibling.querySelector('input.qty').value;
        });
    });

    if (productVariations.length > 0) {
        productVariations.forEach((el) => {
            el.addEventListener('change', (event) => {
                // set add to cart product id to current selected variation
                changeAddToCartProductId(event.target.options[el.selectedIndex].dataset);
            });
        });
    }
}
function initializeToggleSections() {
    document.querySelectorAll('.toggleable-sections').forEach((section) => {
        const checkboxes = section.querySelectorAll('input[type="checkbox"]');

        checkboxes.forEach((checkbox) => {
            checkbox.addEventListener('change', function (e) {
                if (this.checked) {
                    checkboxes.forEach((otherBox) => {
                        if (otherBox !== this) {
                            otherBox.checked = false;
                        }
                    });
                }
            });
        });
    });
}

if (document.readyState !== 'complete') {
    document.addEventListener('DOMContentLoaded', initializeToggleSections);
} else {
    initializeToggleSections();
}

document.body.addEventListener('updated_wc_div', initializeToggleSections, { passive: true });
/* eslint-enable */
